:root {
	--evaluation_favorite_star: #e8d306;
	--wait_dots: #6287AE;
}

body[scheme="client_light"] {
	--customDescription: #000;
}

body[scheme="client_dark"] {
	color: #fff;
	--customDescription: rgba(255, 255, 255, 0.60);
}

body[scheme="bright_light"] {
	color: #fff;
	--customDescription: #000;
}

body[scheme="space_gray"] {
	color: #fff;
	--customDescription: rgba(255, 255, 255, 0.60);
}

body::-webkit-scrollbar-track {
	background-color: var(--separator_common);
}

body::-webkit-scrollbar {
	width: 7px;
	background-color: #fff;
}

body::-webkit-scrollbar-thumb {
	background-color: var(--attach_picker_tab_inactive_text);
	border-radius: 6px;
}

.flex_start {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.flex_end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.flex_center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex_arrow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pb-0 {
	padding-bottom: 0px;
}
.pt-0 {
	padding-top: 0px;
}
.pr-0 {
	padding-right: 0px;
} 
.pl-0 {
	padding-left: 0px;
}
.pt-5 {
	padding-top: 5px;
}
.pb-5 {
	padding-bottom: 5px;
}
.pr-5 {
	padding-right: 5px;
}
.pl-5 {
	padding-left: 5px;
}
.pl-10 {
	padding-left: 10px;
}
.mr-5 {
	margin-right: 5px;
}
.ml-5 {
	margin-left: 5px;
}

.descriptionType {
	color: var(--customDescription);
	font-size: 16px;
	padding-bottom: 5px;
}

.__Light_onePortion {
	border: 1px solid var(--action_sheet_separator);
	border-radius: 10px;
}

.__Light_onePortion__input {
	padding-top: 10px;
}

.__Light_onePortion .Button__content {
	padding-top: 0px;
	padding-left: 5px;
	padding-bottom: 0px;
}

.__Light_onePortion .Button__content:hover {
	cursor: pointer;
}

.Tappable:hover {
	cursor: pointer;
}

.Alert {
	width: 90vw;
}